<template>
	<div class="p-grid">
		<div class="p-col-8">
			<h1>Partner Codes import</h1>
		</div>
		<div class="p-col-4" align="right">
			<a :href="`${publicPath}partner_code_import_template.xlsx`" class="p-button p-component" download>
				<span class="pi pi-download p-button-icon p-button-icon-left"></span>
				<span class="p-button-label">Partner Codes Import Template</span>
			</a>
		</div>
	</div>
	<div class="p-grid">
		<div class="p-col-12">

			<div class="card dropfile" v-if="status === 'empty'">
				<ProcessXLSX :rules="workbookRules" @parsed="workbookParsed" />
			</div>

			<div class="card" v-if="status === 'processing'">
				<p>Processing selected Excel Sheet</p>
				<ProgressBar :value="processingProgress" />
			</div>

			<div class="card" v-if="status === 'ready' || status === 'done'">
				<div class="p-grid">
					<div class="p-col-6">
						<h5 v-if="status === 'ready'">Excel file content</h5>
						<h5 v-if="status === 'done'"><b>Import done - results</b></h5>
					</div>
					<div class="p-col-6" align="right">
						<ConfirmDialog></ConfirmDialog>
						<Button
							class="p-button-danger p-mr-2"
							icon="pi pi-times"
							label="Clear"
							@click="confirmClearing()"></Button>
						<Button
							v-if="status === 'ready'"
							class="p-button-success  p-mr-2"
							icon="pi pi-check"
							label="Import"
							@click="importData()"></Button>
						<Button
							v-if="status === 'done' || status === 'ready'"
							class="p-button-primary p-mr-2"
							icon="pi pi-download"
							label="Download errored rows"
							@click="downloadFailed()"></Button>
					</div>
				</div>
				<div>
					<ImportPartnerCodesTable :workbook="workbook" :flexi="flexi" @toggle-update="toggleUpdate" />
				</div>
			</div>

			<div class="card" v-if="status === 'importing'">
				<p>Importing data</p>
				<ProgressBar :value="importProgress" />
			</div>

			<Dialog
				header="Import Pricelist"
				v-model:visible="importDialog"
				:modal="true"
				:breakpoints="{'1200px': '75vw', '840px': '100vw'}"
				:style="{width: '60vw'}">
				<p>You are trying import an import Excel Sheet with these values. Proceed?</p>
				<DataTable :value="toImport" dataKey="evidence" class="p-datatable-sm">
					<Column header="Evidence">
						<template #body="slotProps">
							<b>{{slotProps.data.evidence}}</b>
						</template>
					</Column>
					<Column field="columns.importFile" header="Import file total"></Column>
					<Column field="columns.new" header="New rows"></Column>
					<Column field="columns.error" header="Rows with error"></Column>
					<Column field="columns.duplicit" header="Duplicit rows"></Column>
					<Column header="Updated rows">
						<template #body="slotProps">
							{{slotProps.data.columns.update}}
						</template>
					</Column>
					<!-- <Column field="columns.update" header="Updated rows"></Column> -->
					<Column field="columns.flexi" header="Already in Flexi"></Column>
				</DataTable>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="importDialog = false"/>
					<Button label="Yes" icon="pi pi-check" autofocus @click="confirmImport()" />
				</template>
			</Dialog>
		</div>
	</div>
</template>

<script>

import wRules from '../service/xlsxParser/parseInRules/partnerCodesParseRules.js'
import FlexiAPI from '../service/flexiAPI/flexiAPI.js';
import XLSX from 'xlsx'

// Import components
import ProcessXLSX from '../components/ProcessXLSX.vue'
import {
	ImportPartnerCodesTable
} from '../components/import-partner-codes/index.js'

export default {
	data() {
		return {
			workbookRules: wRules,
			workbook: {
				kody: null
			},
			flexi: null,
			processingProgress: 0.0,
			toImportTotal: 0,
			importCounter: 0,
			status: 'empty',
			importDialog: false,
			publicPath: process.env.BASE_URL
		}
	},
	computed: {
		importProgress() {
			const tmp = this.toImportTotal ? this.importCounter / this.toImportTotal : 1
			return Math.round(tmp*10000)/100
		},

		toImport() {
			const ret = [{
				evidence: 'Partner codes',
				columns: {
					importFile: this.workbook.kody.length,
					new: this.workbook.kody.filter(a => a._status === 'OK').length,
					error: this.workbook.kody.filter(a => a._status === 'E').length,
					duplicit: this.workbook.kody.filter(a => a._status === 'D').length,
					update: this.workbook.kody.filter(a => a._status === 'U').length,
					selected: this.workbook.kody.filter(a => a._status === 'U' && a._selected).length,
					flexi: this.flexi.lists.dodavatele.length + this.flexi.lists.odberatele.length
				}
			}]

			return ret
		}
	},
	methods: {
		async workbookParsed(workbook) {

			this.workbook = workbook
			this.status = 'processing'

			this.processingProgress = 5.0

			this.flexi = await FlexiAPI.partnerCodes.get()
			this.processingProgress = 95.0

			this.processWorkbook()
			this.processingProgress = 100.0

			this.status = 'ready'
		},
		processWorkbook() {
			console.log('Workbook parsed')

			if (!this.workbook || !this.workbook.kody) {
				throw new Error('Unknwn Excel file. File must contain a sheet named \'Kódy\'')
			}

			const dodavVztahy = ['typVztahu.odberDodav', 'typVztahu.dodavatel', 'typVztahu.vyrobce']
			const odberVztahy = ['typVztahu.odberDodav', 'typVztahu.odberatel']

			for (const row of this.workbook.kody) {
				// Bind produkt
				let prodByCode = null
				let prodByEan = null
				if (row.nas_kod) {
					prodByCode = this.flexi.maps.cenik.get(row.nas_kod)
				}
				if (row.ean) {
					prodByEan = this.flexi.maps.cenikEan.get(row.ean.toString())
				}

				if (!prodByCode && !prodByEan) {
					row._status = 'E'
					row._errors.push(new Error(`Product ${row.nas_kod}:${row.ean}' not found. Import it first.`))
				} else if (prodByCode !== prodByEan && prodByCode && prodByEan) {
					row._status = 'E'
					row._errors.push(new Error(`Combination of product Code ${row.nas_kod} and product EAN ${row.ean}' is ambiguous.`))
				} else if (prodByCode){
					row.cenik = prodByCode
				} else {
					row.cenik = prodByEan
				}

				// Bind firma
				row.firma = this.flexi.maps.firma.get(row.partner)
				if (!row.firma) {
					row._status = 'E'
					row._errors.push(new Error(`Partner ${row.partner} not found.`))
				}

				// Bind dodav/odber
				if (row._status !== 'E') {
					const cenikFirmKod = `${row.cenik.kod}-${row.firma.kod}`
					row.dodav = this.flexi.maps.dodavatel.get(cenikFirmKod)
					row.odber = this.flexi.maps.odberatel.get(cenikFirmKod)
					row._isDodav = dodavVztahy.includes(row.firma.typVztahuK)
					row._isOdber = odberVztahy.includes(row.firma.typVztahuK)
					row._dodavStatus = 'OK'
					row._odberStatus = 'OK'
					if (row.dodav) {
						if (row.dodav.kodIndi === row.upper_kod_u_partnera) {
							row._dodavStatus = 'D'
						} else {
							row._dodavStatus = 'U'
						}
					}
					if (row.odber) {
						if (row.odber.kodIndi === row.upper_kod_u_partnera) {
							row._odberStatus = 'D'
						} else {
							row._odberStatus = 'U'
						}
					}

					// Check if it's new or to update
					// - must also match partnerType to dodav/odberatel
					if (!row._isDodav && !row._isOdber) {
						row._status = 'E'
						row._errors.push(new Error(`Partner ${row.firma.kod} is not odberatel or dodavatel. It's type: ${row.firma.typVztahuK}`))
					}

					// If both entries are there and not changed, line is duplicit
					if (row._dodavStatus === 'D' && row._odberStatus === 'D') {
						row._status = 'D'
					}

					if (row._isDodav && row._dodavStatus === 'D' && row._odberStatus === 'OK') {
						row._status = 'D'
					}
					if (row._isOdber && row._odberStatus === 'D' && row._dodavStatus === 'OK') {
						row._status = 'D'
					}

					if (row._isDodav && row._dodavStatus === 'OK' && row._status === 'D') {
						row._status = 'U'
					}
					if (row._isOdber && row._odberStatus === 'OK' && row._status === 'D') {
						row._status = 'U'
					}

					// If any of entries was updated the whole status is U
					if (row._dodavStatus === 'U' || row._odberStatus === 'U') {
						row._status = 'U'
					}

					// if (row._dodavStatus === 'D' || row._odberStatus === 'D') {
					// 	if (row._isDodav && row._odberStatus === 'OK') {
					// 		row._status = 'U'
					// 	}
					// 	if (row._isOdber && row._dodavStatus === 'OK') {
					// 		row._status = 'U'
					// 	}
					// }
				}
			}

			console.log('Workbook processing done')
		},

		clear() {
			this.workbook.kody = null

			this.flexi = null

			this.processingProgress = 0.0
			this.importCounter = 0
			this.toImportTotal = 0
			this.status = 'empty'
		},
		confirmClearing() {
			this.$confirm.require({
				message: 'Are you sure you want to proceed? All changes will be lost.',
				header: 'Clear loaded file',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.clear()
				}
			})
		},
		importData() {
			this.importDialog = true

		},
		async confirmImport() {
			this.importDialog = false
			this.status = 'importing'

			const total = this.workbook.kody.filter(a => a._status === 'OK' || (a._status === 'U' && a._selected)).length
			this.toImportTotal = total

			// Import Partner Codes
			for (let cod of this.workbook.kody) {
				if (cod._status === 'OK' || (cod._status === 'U' && cod._selected)) {
					let isD = true
					if ((cod._isDodav && cod._dodavStatus === 'OK') || cod._dodavStatus === 'U') {
						// Write dodavatel
						const entry = {
							firma: cod.firma.kod,
							cenik: cod.cenik.kod,
							kodIndi: cod.upper_kod_u_partnera
						}
						try {
							const res = await FlexiAPI.partnerCodes.post(entry, false)
						} catch (e) {
							cod._status = 'E'
							cod._errors.push(new Error(`Dodavatel ${cod.firma} of product ${cod.cenik} import error: ${e}`))
							isD = false
						}
					}
					if ((cod._isOdber && cod._odberStatus === 'OK') || cod._odberStatus === 'U') {
						// Write odberatel
						const entry = {
							firma: cod.firma.kod,
							cenik: cod.cenik.kod,
							kodIndi: cod.upper_kod_u_partnera
						}
						try {
							const res = await FlexiAPI.partnerCodes.post(entry, true)
						} catch (e) {
							cod._status = 'E'
							cod._errors.push(new Error(`Odberatel ${cod.firma} of product ${cod.cenik} import error: ${e}`))
							isD = false
						}
					}
					if (isD) {
						cod._status = 'D'
					}

					this.importCounter++;
				}
			}

			this.status = 'done';
		},

		downloadFailed() {
			const wb = XLSX.utils.book_new()

			const entries = {
				cenik: this.workbook.kody ? this.workbook.kody.filter(it => it._status === 'E') : []
			}

			if (entries.cenik.length) {
				const cenikWs = XLSX.utils.aoa_to_sheet([['EAN', 'Náš Kód', 'Partner', 'Kód u Partnera', 'Typ Partnera', 'Chyba'],
				...entries.cenik.map(prd => {
					return [
						prd.ean ? prd.ean.toString() : null,
						prd.nas_kod,
						prd.partner,
						prd.kod_u_partnera,
						prd.firma ? prd.firma.typVztahuK : null,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, cenikWs, "Kódy")
			}

			const date = (new Date()).toISOString().replace('T', '_').replace(':','-').replace(':', '-').substring(0,19)
			XLSX.writeFile(wb, `${date}_failed_import_partnerske_kody.xlsx`);
		},

		toggleUpdate(row) {
			row._selected = !row._selected
		}
	},
	components: {
		ProcessXLSX,
		ImportPartnerCodesTable
	}
}
</script>

<style scoped>

a.p-button {
	cursor: pointer;
}

h1 {
	font-size: 2rem;
}

</style>
