export default {
  kody: {
    columns: [
      'ean:EAN',
      'nas_kod:PRODKOD',
      'partner:!ASCIIU(2,16)',
      'kod_u_partnera:!STRING(1,64)'
    ],
    hasHeader: true,
    computed: {
      upper_kod_u_partnera: row => {
        return row.kod_u_partnera.toUpperCase()
      }
    }
  }
}
